import React, { useState, useEffect, useRef } from "react";
import { Mic, MicOff, HelpCircle } from "lucide-react";
import { createPortal } from "react-dom";
import "./VoiceCommands.css";

const VOICE_COMMANDS = [
  {
    command: "create image slide",
    description: "Creates a new image slide"
  },
  {
    command: "create text slide",
    description: "Creates a new text slide"
  },
  {
    command: "create video slide",
    description: "Creates a new video slide"
  },
  {
    command: "create weather slide",
    description: "Creates a new weather slide"
  },
  {
    command: "create rss slide",
    description: "Creates a new RSS feed slide"
  },
  {
    command: "create interest rate slide",
    description: "Creates a new interest rate slide"
  },
  {
    command: "create connector slide",
    description: "Creates a new Ve-Branch Connector slide"
  },
  {
    command: "show dashboard",
    description: "Navigate to the dashboard"
  },
  {
    command: "show control panel",
    description: "Navigate to the control panel"
  }
];

const VoiceCommands = ({ onCommand }) => {
  const [isListening, setIsListening] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('error');
  const [showHelp, setShowHelp] = useState(false);
  const recognitionRef = useRef(null);

  useEffect(() => {
    // Check if browser supports speech recognition
    if (
      !("webkitSpeechRecognition" in window) &&
      !("SpeechRecognition" in window)
    ) {
      setMessage("Speech recognition is not supported in this browser.");
      setMessageType('error');
      return;
    }

    // Initialize speech recognition
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    const recognition = recognitionRef.current;

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      const command = event.results[0][0].transcript.toLowerCase();
      setIsListening(false);

      // Handle different commands
      let commandRecognized = true;
      if (command.includes("create image slide")) {
        onCommand("CREATE_IMAGE_SLIDE");
      } else if (command.includes("create text slide")) {
        onCommand("CREATE_TEXT_SLIDE");
      } else if (command.includes("create video slide")) {
        onCommand("CREATE_VIDEO_SLIDE");
      } else if (command.includes("create weather slide")) {
        onCommand("CREATE_WEATHER_SLIDE");
      } else if (command.includes("create rss slide")) {
        onCommand("CREATE_RSS_SLIDE");
      } else if (command.includes("create interest rate slide")) {
        onCommand("CREATE_INTEREST_RATE_SLIDE");
      } else if (command.includes("create connector slide")) {
        onCommand("CREATE_CONNECTOR_SLIDE");
      } else if (command.includes("show dashboard")) {
        onCommand("SHOW_DASHBOARD");
      } else if (command.includes("show control panel")) {
        onCommand("SHOW_CONTROL_PANEL");
      } else {
        commandRecognized = false;
      }

      if (commandRecognized) {
        setMessage(`Command executed: ${command}`);
        setMessageType('success');
      } else {
        setMessage(`Unrecognized command: ${command}`);
        setMessageType('error');
      }
    };

    recognition.onerror = (event) => {
      // Only set error if it's not the "no-speech" error
      if (event.error !== "no-speech") {
        console.error("Speech recognition error:", event.error);
        setMessage(`Error: ${event.error}`);
        setMessageType('error');
      }
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    // Cleanup function
    return () => {
      if (recognition) {
        recognition.abort();
      }
    };
  }, []); // Only run once on mount

  useEffect(() => {
    const recognition = recognitionRef.current;
    if (!recognition) return;

    if (isListening) {
      setMessage(null); // Clear any previous errors
      setMessageType('error'); // Reset message type
      recognition.start();
    } else {
      recognition.stop();
    }
  }, [isListening]);

  return (
    <div className="voice-commands-container">
      {message && (
        <div className={`message-container message-${messageType}`}>
          {message}
        </div>
      )}

      <div className="voice-controls">
        <button
          onClick={() => setIsListening(!isListening)}
          className={`voice-button ${
            isListening ? "voice-button-listening" : "voice-button-idle"
          }`}
          title={isListening ? "Stop listening" : "Start listening"}
        >
          {isListening ? (
            <Mic className="mic-icon mic-icon-pulse" />
          ) : (
            <MicOff className="mic-icon" />
          )}
        </button>

        <button
          onClick={() => setShowHelp(true)}
          className="help-button"
          title="Show available commands"
        >
          <HelpCircle className="help-icon" />
        </button>
      </div>

      {showHelp && createPortal(
        <div className="help-modal-overlay" onClick={() => setShowHelp(false)}>
          <div className="help-modal" onClick={e => e.stopPropagation()}>
            <h2>Available Voice Commands</h2>
            <div className="commands-list">
              {VOICE_COMMANDS.map((cmd, index) => (
                <div key={index} className="command-item">
                  <span className="command-phrase">"{cmd.command}"</span>
                  <span className="command-description">{cmd.description}</span>
                </div>
              ))}
            </div>
            <button 
              className="close-button"
              onClick={() => setShowHelp(false)}
            >
              Close
            </button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default VoiceCommands;
