import React from "react";
import { Switch } from "react-router-dom";
import Header from "./Header";
import Matrix from "./Matrix";
import Instructions from "./Instructions";
import ControlPanel from "./ControlPanel";
import "./Dashboard.css";

import PrivateRoute from "../private-route/PrivateRoute";
import EditChannel from "./EditChannel";
import EditUser from "./EditUser";
import EditArea from "./EditArea";
import SortSlides from "./SortSlides";
import Archive from "./Archive";
import ChooseSlideType from "../SlideEditor/ChooseSlideType";
import EditSlide from "../SlideEditor/EditSlide";
import PortalSwitcher from "../misc/PortalSwitcher";
import Changelog from "./Changelog";
import WebmasterControlPanel from "./Webmaster/WebmasterControlPanel";
import SortChannels from "./SortChannels";
import SortAreas from "./SortAreas";
import VoiceCommands from "./VoiceCommands";
import { setSelectedSlideType } from "../../actions/selectionActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { slideTypes } from "../../utils/slideHelpers";

function Dashboard(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const handleVoiceCommand = (command) => {
    switch (command) {
      case "CREATE_IMAGE_SLIDE":
        // Navigate to text slide creation
        dispatch(setSelectedSlideType(slideTypes.image));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_TEXT_SLIDE":
        // Navigate to text slide creation
        dispatch(setSelectedSlideType(slideTypes.rte));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_VIDEO_SLIDE":
        // Navigate to video slide creation
        dispatch(setSelectedSlideType(slideTypes.video));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_WEATHER_SLIDE":
        // Navigate to weather slide creation
        dispatch(setSelectedSlideType(slideTypes.weather));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_RSS_SLIDE":
        // Navigate to rss slide creation
        dispatch(setSelectedSlideType(slideTypes.rss));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_INTEREST_RATE_SLIDE":
        // Navigate to animated slide creation
        dispatch(setSelectedSlideType(slideTypes.interestrate));
        props.history.push("/dashboard/EditSlide");
        break;
      case "CREATE_CONNECTOR_SLIDE":
        // Navigate to template slide creation
        dispatch(setSelectedSlideType(slideTypes.wpRss));
        props.history.push("/dashboard/EditSlide");
        break;
      case "SHOW_DASHBOARD":
        // Navigate to dashboard
        props.history.push("/dashboard/");
        break;
      case "SHOW_CONTROL_PANEL":
        // Navigate to control panel
        props.history.push("/dashboard/controlpanel/");
        break;
    }
  };

  return (
    <div>
      <Header />
      <div className="dashboard-content">
        <PrivateRoute exact path="/dashboard" component={PortalSwitcher} />
        <PrivateRoute
          exact
          path="/dashboard/controlpanel"
          component={PortalSwitcher}
        />
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Matrix} />
          <PrivateRoute
            exact
            path="/dashboard/instructions"
            component={Instructions}
          />
          <PrivateRoute
            path="/dashboard/webmaster"
            component={WebmasterControlPanel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel"
            component={ControlPanel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/editchannel"
            component={EditChannel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/edituser"
            component={EditUser}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/editarea"
            component={EditArea}
          />

          <PrivateRoute
            exact
            path="/dashboard/controlpanel/sortchannels"
            component={SortChannels}
          />

          <PrivateRoute
            exact
            path="/dashboard/controlpanel/sortareas"
            component={SortAreas}
          />

          <PrivateRoute
            exact
            path="/dashboard/sortslides"
            component={SortSlides}
          />

          <PrivateRoute
            exact
            path="/dashboard/chooseslidetype"
            component={ChooseSlideType}
          />
          <PrivateRoute
            exact
            path="/dashboard/EditSlide"
            component={EditSlide}
          />
          <PrivateRoute exact path="/dashboard/archive" component={Archive} />

          <PrivateRoute
            exact
            path="/dashboard/changelog"
            component={Changelog}
          />
        </Switch>
        {user.isSuperUser && (
          <div className="voice-commands-wrapper">
            <VoiceCommands onCommand={handleVoiceCommand} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
