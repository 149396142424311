import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Slide } from "./slides/Slide";
import {
  getNextSlideIndex,
  getSlideIndexSynchronized,
} from "../utils/slideHelpers";
import * as QueryString from "query-string";

export function DisplayChannel(props) {
  let location = window.location.pathname;
  let portal = parseInt(location.split("/")[4]);
  if (portal === 16 || portal === 25) {
    portal = 54;
  }
  let channelName = location.split("/")[5].replace(/%20/g, " ");

  const second = 1000; //as duration is set in seconds, we need to multiply by this to put in milliseconds for setTimeout. This can be lowered to make testing go faster.

  const [channel, setChannel] = useState({});

  const [currentSlide, setCurrentSlide] = useState(null);

  //this is only used to create an effect that happens once when channel and currentslide are loaded that won't repeat when currentslide is changed
  const [loaded, setLoaded] = useState(false);

  let time = null;
  let lastUpdate = Date.now();

  //LOAD CHANNEL DATA and set up the starting slides
  const params = QueryString.parse(props.location.search);
  useEffect(() => {
    axios
      .post("/api/channels/getchannelfordisplay", {
        channel: { PortalId: portal, originalName: channelName },
        TestMode: params.TestMode === "true",
        browser: { UserAgent: window.navigator.userAgent },
      })
      .then((res) => {
        if (res.data.error) {
          console.log(`Error in getchannelfordisplay response: `);
          console.log(res.data.error);
          //alert(res.data.error);
        } else {
          //FOR TESTING/DEVELOPMENT PURPOSES - FILTER SLIDES BY TYPE
          /*
        res.data.selectedSlides = res.data.selectedSlides.map((item) => {
          if (item.SlideIDs)
            item.SlideIDs = item.SlideIDs.filter(
              (slide) => slide.type == slideTypes.rte.index
            );
          return item;
        });*/

          setChannel(res.data);
          let currentSlides = [];
          res.data.selectedSlides.forEach((area, index) => {
            let slide = area.SlideIDs[0];
            if (slide) {
              slide.index = 0;
              currentSlides.push(slide);
            }
          });
          setCurrentSlide(currentSlides);

          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(`Error processing getchannelfordisplay response:`);
        console.log(err);
        //alert(err);
      });
  }, [portal, channelName, props.location.search, params.TestMode]);

  const switchSlides = useCallback(
    (areaIndex, slideIndex) => {
      //we may want to re-sync with the server at some point
      if (!time) {
        axios.get("/serverTime").then((res) => {
          time = res.data;
          console.log(`Got server time: ${time}`);
          setTimeout(() => switchSlides(areaIndex, 0), 100);
        });
      } else {
        let selectedSlides = channel.selectedSlides[areaIndex];
        if (selectedSlides === undefined) return;
        //let currentSlide = selectedSlides.SlideIDs[slideIndex];

        //get the next slide in this area.
        //let nextSlideIndex = getNextSlideIndex(channel, areaIndex, slideIndex);
        let nextSlideIndex = getSlideIndexSynchronized(
          channel,
          areaIndex,
          time
        );
        let nextSlide = selectedSlides.SlideIDs[nextSlideIndex];
        nextSlide.index = nextSlideIndex;

        //replace the old slide in this area with the new slide
        if (nextSlideIndex !== slideIndex)
          setCurrentSlide((c) =>
            c.map((item, idx) => {
              if (idx === areaIndex) {
                return nextSlide;
              } else return item;
            })
          );

        //let duration = nextSlide.duration * second;
        //setTimeout(() => switchSlides(areaIndex, nextSlideIndex), duration);

        //if the number of milliseconds since midnight on January 1, 1970, UTC is exactly divisible by 100, wait 100 ms, otherwise, add time to try to get it to a multiple of 100.
        //this is to ensure the switchSlides function happens at the same time across all players that are synced to the same time.
        let timeout = time % 100 === 0 ? 100 : 100 + (time % 100);

        //use difference in local time since this ran last to add to the time received from the server.
        var now = Date.now();
        var dt = now - lastUpdate;
        lastUpdate = now;

        time += dt;

        //console.log(`Time is ${time}.`);
        setTimeout(() => switchSlides(areaIndex, nextSlideIndex), timeout);
      }
    },
    [channel]
  );

  //once loaded, start switching slides in each area.
  useEffect(() => {
    if (channel.selectedSlides) {
      channel.selectedSlides.forEach((item, idx) => {
        //make sure there's actually a slide in the area, and another to switch to.
        if (item.SlideIDs.length > 1) {
          switchSlides(idx, 0);
        }
      });
    }
  }, [loaded, channel, switchSlides]);

  useEffect(() => {
    if (!channel.disableAutoRefresh && loaded) {
      //default 1 hour refresh
      let duration = (channel.refreshFrequencyMinutes || 60) * 60 * 1000;
      console.log("Setting auto refresh timer..." + duration);

      setInterval(() => {
        //check this route to ensure internet connection
        axios
          .post("/api/channels/getforcedrefresh", {
            PortalId: portal,
            originalName: channelName,
          })
          .then((res) => {
            if (res.data !== undefined) {
              document.location.reload();
            }
          })
          .catch(() => console.log("internet connection issue."));
      }, duration);
    }
  }, [loaded, channel.disableAutoRefresh, channel.refreshFrequencyMinutes]);

  useEffect(() => {
    console.log("Manual refresh timer effect.");
    const checkForcedRefresh = () => {
      axios
        .post("/api/channels/getforcedrefresh", {
          PortalId: portal,
          originalName: channelName,
        })
        .then((res) => {
          if (res.data === true) {
            document.location.reload();
          }
        });
    };
    if (!channel.disableForceRefreshCheck && params.TestMode !== "true") {
      //default 2 minute force refresh
      let duration =
        (channel.forcedRefreshCheckFrequencyMinutes || 2) * 60 * 1000;
      console.log("Setting check manual refresh timer..." + duration);
      setInterval(() => checkForcedRefresh(), duration);
    }
  }, [
    channel.disableForceRefreshCheck,
    channel.forcedRefreshCheckFrequencyMinutes,
    channelName,
    portal,
    params.TestMode,
  ]);

  if (channel.responsive) {
    channel.width = Math.min(channel.width, 100);
    channel.height = Math.min(channel.height, 100);
  }
  return (
    <div
      className="channel"
      style={{
        backgroundImage: `url('${channel.backgroundimage}')`,
        width: channel.width
          ? `${channel.width}${channel.responsive ? "%" : "px"}`
          : "100%",
        height: channel.height
          ? `${channel.height}${channel.responsive ? "%" : "px"}`
          : "100%",
        overflow: "hidden",
        position: "absolute",
        cursor: window.location.href.includes("localhost") ? "auto" : "none",
        backgroundSize: channel.responsive ? "100% 100%" : "auto",
      }}
    >
      {channel.selectedSlides
        ? channel.selectedSlides.map((item, idxArea) => {
            //if a new area has no slides it won't be in the selectedSlides array so the area won't display. Not sure if that could make any meaningful difference though...
            const area = item.AreaID;
            if (item.SlideIDs.length === 0) return null;
            if (area) {
              const areaStyles = {
                position: "absolute",
                left: area.responsive ? `${area.x}%` : area.x,
                top: area.responsive ? `${area.y}%` : area.y,
                width: area.responsive
                  ? area.widthResponsive
                    ? area.widthResponsive
                    : `${area.width}%`
                  : area.width,
                height: area.responsive
                  ? area.heightResponsive
                    ? area.heightResponsive
                    : `${area.height}%`
                  : area.height,
                backgroundColor: area.backgroundColour,
                zIndex: area.z,
                overflow: "hidden",
              };

              return (
                <div className="area" key={idxArea} style={areaStyles}>
                  {item.SlideIDs.map((item, idxSlide) => {
                    const now = new Date();
                    const start = new Date(item.scheduleStart);
                    const end = new Date(item.scheduleEnd);
                    let show = false;
                    if (item.alwaysDisplay) show = true;
                    if (item.alwaysDisplayAfterStartDate && now >= start)
                      show = true;
                    if (now >= start && now <= end) show = true;

                    return show ? (
                      <Slide
                        key={idxSlide}
                        slide={item}
                        area={area}
                        areaIndex={idxArea}
                        slideIndex={idxSlide}
                        channel={channel}
                        currentSlide={currentSlide}
                        preview={false}
                      ></Slide>
                    ) : null;
                  })}
                </div>
              );
            } else return null;
          })
        : "Loading content..."}
    </div>
  );
}
